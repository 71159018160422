'use strict';

(function (w, d, c) {
	/*
 	Sticky header
 */
	var header = d.querySelector('#header > header');
	var stuck = false;
	var threshold = 5; // from top pixels

	function adjustHeader() {
		if (w.pageYOffset >= threshold && stuck === false) {
			stuck = true;
			addClass(header, 'sticky');
		} else if (w.pageYOffset < threshold && stuck === true) {
			stuck = false;
			removeClass(header, 'sticky');
		}
	}

	adjustHeader();

	window.addEventListener('scroll', adjustHeader);

	/*
 	Open expandable boxes
 */
	var headings = d.querySelectorAll('.collapsable > h3, .collapsable > h4');

	Array.prototype.forEach.call(headings, function (heading, i) {
		var container = heading.parentNode;
		addClass(container, 'closed');

		heading.addEventListener('click', function (event) {
			if (hasClass(container, 'closed')) {
				removeClass(container, 'closed');
			} else {
				addClass(container, 'closed');
			}
		});
	});

	/*
 	DOM functions
 */
	function hasClass(el, className) {
		if (el.classList) {
			return el.classList.contains(className);
		} else {
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
		}
	}
	function addClass(el, className) {
		if (el.classList) {
			el.classList.add(className);
		} else {
			el.className += ' ' + className;
		}
	}
	function removeClass(el, className) {
		if (el.classList) {
			el.classList.remove(className);
		} else {
			el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}
})(window, document, console);